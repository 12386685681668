<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.036 22.2902C17.5588 22.2969 22.0414 17.8252 22.0481 12.3024C22.0548 6.77955 17.5831 2.29695 12.0603 2.29024C6.53745 2.28352 2.05486 6.75523 2.04814 12.2781C2.04143 17.8009 6.51313 22.2835 12.036 22.2902Z"
      fill="#DB6D39"
    />
    <path
      d="M12.053 8.29028L12.0481 12.2903"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0433 16.2903L12.0533 16.2903"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AttentionCircle',
}
</script>
