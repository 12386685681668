import { render, staticRenderFns } from "./AttentionCircle.vue?vue&type=template&id=765c2619&"
import script from "./AttentionCircle.vue?vue&type=script&lang=js&"
export * from "./AttentionCircle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('765c2619')) {
      api.createRecord('765c2619', component.options)
    } else {
      api.reload('765c2619', component.options)
    }
    module.hot.accept("./AttentionCircle.vue?vue&type=template&id=765c2619&", function () {
      api.rerender('765c2619', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/AttentionCircle.vue"
export default component.exports